import React from 'react'
import classes from './Introduction.module.css'
import { useDispatch } from 'react-redux'
import { openVideoModal } from '@/store/actions/modals'
import { Button } from '../Button'
import { PlayVideoArrow } from '../../images/svg/Arrows'
import {
  watchVideoMainPageLink,
  watchVideoMainPageLinkMP4,
} from '../../constants/video-links'
import ym from 'react-yandex-metrika'

export const Introduction = () => {
  const dispatch = useDispatch()
  return (
    <section className={classes.section}>
      <div className={classes.layer} />
      <video
        playsinline='playsinline'
        muted='muted'
        loop='loop'
        autoplay='autoplay'
        className={classes.video}
      >
        <source src={watchVideoMainPageLink} type='video/webm' />
        {/* Safari не поддреживает webm-формат */}
        <source src={watchVideoMainPageLinkMP4} type='video/mp4' />
      </video>
      <div className={classes.section__container}>
        <div className={classes.section__title}>
          XV Фестиваль науки Юга России:
        </div>
        <div className={classes.section__suptitle}>Логика познания</div>
        <div className={classes.section__date}>
          <span>14 сентября 2024 г.</span>
        </div>
        {/* <div className={classes.section__date}>
          <span>24 сентября с 11:00 до 18:00</span>
          <span>25 сентября с 11:00 до 17:00</span>
        </div> */}
        <div className={classes.view}>
          <Button
            onClick={() => {
              dispatch(openVideoModal())
              ym('reachGoal', 'CLICK_ON_VIDEO')
            }}
            theme='primary'
            iconAfterText
            customIcon={<PlayVideoArrow />}
          >
            Смотреть видео
          </Button>
        </div>
      </div>
    </section>
  )
}
