import React from 'react'
import classes from './Button.module.css'
import cn from 'classnames'
import { RightArrow, CheckIcon } from '../../images/svg'

export function Button({
  theme,
  children,
  className,
  size,
  iconAfterText = false,
  arrowTheme = 'light',
  customIcon,
  iconBeforeText,
  ...otherProps
}) {
  const LIGHT_THEME = arrowTheme === 'light'
  const DRAK_THEME = arrowTheme === 'dark'
  return (
    <button
      className={cn(
        classes.button,
        theme && classes[theme],
        size && classes[size],
        className,
        iconAfterText && classes.arrow
      )}
      {...otherProps}
    >
      {iconBeforeText && (customIcon || <CheckIcon />)}
      {children}
      {iconAfterText &&
        (customIcon || (
          <RightArrow
            className={cn({
              [classes.light__arrow]: LIGHT_THEME,
              [classes.dark__arrow]: DRAK_THEME,
            })}
          />
        ))}
    </button>
  )
}
