import {
  OPEN_LOGIN_MODAL,
  OPEN_LOGOUT_MODAL,
  OPEN_REG_MODAL,
  OPEN_VIDEO_MODAL,
  CLOSE_LOGIN_MODAL,
  CLOSE_LOGOUT_MODAL,
  CLOSE_REG_MODAL,
  CLOSE_VIDEO_MODAL,
} from '../types'

export function openLoginModal() {
  return {
    type: OPEN_LOGIN_MODAL,
  }
}

export function openLogoutModal() {
  return {
    type: OPEN_LOGOUT_MODAL,
  }
}

export function openRegModal() {
  return {
    type: OPEN_REG_MODAL,
  }
}

export function openVideoModal() {
  return {
    type: OPEN_VIDEO_MODAL,
  }
}

export function closeLoginModal() {
  return {
    type: CLOSE_LOGIN_MODAL,
  }
}

export function closeLogoutModal() {
  return {
    type: CLOSE_LOGOUT_MODAL,
  }
}

export function closeRegModal() {
  return {
    type: CLOSE_REG_MODAL,
  }
}

export function closeVideoModal() {
  return {
    type: CLOSE_VIDEO_MODAL,
  }
}
