import request from './request'

const authData = {
  client_id: process.env.GATSBY_APP_CLIENT_ID,
  client_secret: process.env.GATSBY_APP_CLIENT_SECRET,
}

const fullTokenUrl =
  String(process.env.GATSBY_APP_TARGET) +
  String(process.env.GATSBY_APP_TOKEN_PATH)

export function logout() {
  return request({
    url: '/login/logout',
    method: 'post',
  })
}

export function refreshToken(refresh_token) {
  const params = Object.assign(authData, {
    grant_type: 'refresh_token',
    refresh_token,
  })
  return request({
    url: fullTokenUrl,
    method: 'get',
    params,
  })
}
