import { getCategoryEvents } from '../api/media'

const getEvents = async ({ pageParam = 1 }) => {
  const res = await getCategoryEvents({
    type: 'events',
    limit: 16,
    page: pageParam,
  })

  const data = {
    results: res.data.items,
    next: res.data.countOfPages === pageParam ? undefined : pageParam + 1,
  }
  return data
}
export default getEvents
