import { MIN_VALUE, ZERO_STR } from '../../../services/constans'

export const days = month => {
  switch (month) {
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
      return generateDays(31)
    case 4:
    case 6:
    case 9:
    case 11:
      return generateDays(30)
    case 2:
      return generateDays(29)
    default:
      return []
  }
}

const generateDays = shouldBeDays => {
  const days = []
  for (let i = 1; i <= shouldBeDays; i++) {
    days.push({
      value: i,
      label: i,
    })
  }
  return days
}

export const dateHandler = data => ({
  month: data.month < MIN_VALUE ? ZERO_STR + data.month : data.month,
  day: data.day < MIN_VALUE ? ZERO_STR + data.day : data.day,
})
