import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { VictorinaAnswer } from './VictorinaAnswer/VictorinaAnswer'
import { VictorinaSelect } from './VictorinaSelect/VictorinaSelect'
import s from './Victorina.module.css'
import backgroundAnswer from '@/images/bgVictorinaAnswer.jpg'
import backgroundSelect from '@/images/bg_victorina.jpg'

export const Victorina = () => {
  const isAnswer = useSelector(state => state.app.isAnswer)
  const data = useSelector(state => state.app.infoVictorina)

  const background = useMemo(() => {
    if (data?.items?.length) {
      return (
        process.env.GATSBY_APP_TARGET + '/uploads/' + data.items[0].image.path
      )
    }
    return isAnswer ? backgroundAnswer : backgroundSelect
  }, [isAnswer, data])

  return (
    <section className={s.section}>
      <LazyLoadImage className={s.background} src={background} />
      <div className={s.victorina}>
        <div className={s.container}>
          {isAnswer ? <VictorinaAnswer /> : <VictorinaSelect />}
        </div>
      </div>
    </section>
  )
}
