import request from '@/services/request'
import {
  LOGIN,
  LOGOUT,
  SET_AUTH_ERROR,
  RESET_AUTH_ERROR,
  OPEN_VICTORINA,
  CLOSE_VICTORINA,
  SET_CATEGORY,
} from '../types'

export function logout() {
  window.localStorage.removeItem('access_token')
  window.localStorage.removeItem('refresh_token')
  window.localStorage.removeItem('currentUser')
  return {
    type: LOGOUT,
  }
}

export function login() {
  return {
    type: LOGIN,
  }
}

function authError() {
  return {
    type: SET_AUTH_ERROR,
  }
}

export function resetAuthError() {
  return {
    type: RESET_AUTH_ERROR,
  }
}
export const setCategory = number => ({
  type: SET_CATEGORY,
  payload: number,
})

export function openVictorinaInfo(payload) {
  return {
    type: OPEN_VICTORINA,
    payload,
  }
}

export function closeVictorinaInfo() {
  return {
    type: CLOSE_VICTORINA,
  }
}

export function tryLogin(query) {
  return async dispatch => {
    const data = await loginQuery(query)
    if (!data) dispatch(authError())
    else {
      window.localStorage.setItem('access_token', data.access_token)
      window.localStorage.setItem('refresh_token', data.refresh_token)
      dispatch(login())
      const currentUser = await getCurrentUser()
      window.localStorage.setItem('currentUser', currentUser.id)
    }
  }
}

export function trySendTime(eventId, hash) {
  return async dispatch => {
    const data = await sendTime(eventId, hash)
    return data
  }
}

export function tryGetCurrentEvents(params) {
  return async dispatch => {
    const data = await getCurrentEvents(params)
    return data
  }
}

export function tryGetVictorina(params) {
  return async dispatch => {
    const data = await getVictorina(params)
    dispatch(openVictorinaInfo(data))
  }
}
const eventsUrl = process.env.GATSBY_APP_TARGET + '/api/events'
const userExcursionUrl = process.env.GATSBY_APP_TARGET + '/api/user_excursions'
const victorinaUrl = process.env.GATSBY_APP_TARGET + '/api/quizzes'
const authData = {
  client_id: process.env.GATSBY_APP_CLIENT_ID,
  client_secret: process.env.GATSBY_APP_CLIENT_SECRET,
}

const fullTokenUrl =
  String(process.env.GATSBY_APP_TARGET) +
  String(process.env.GATSBY_APP_TOKEN_PATH)
const currentUrl = String(process.env.GATSBY_APP_TARGET) + '/api/users/current'

export const getVictorina = async date => {
  const dateParams = `${date.month}-${date.day}`
  const url = new URL(victorinaUrl)
  url.searchParams.set('date', dateParams)

  const { data } = await request({
    url: url.href,
    method: 'get',
  })
  return data
}

async function loginQuery({ username, password }) {
  try {
    const params = Object.assign(authData, {
      grant_type: 'password',
      username,
      password,
    })
    const { data } = await request({
      url: fullTokenUrl,
      method: 'get',
      params,
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getCurrentUser = async () => {
  try {
    const { data } = await request({
      url: currentUrl,
      method: 'get',
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

export const postUserExcursion = async data => {
  const res = await request({
    url: userExcursionUrl,
    method: 'post',
    data,
  })
  return res
}

async function sendTime(eventId, hash) {
  const url = `${eventsUrl}/${eventId}/add-time`
  try {
    const { data } = await request({
      url: url,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage['access_token']}`,
      },
      data: {
        hash,
      },
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

async function getCurrentEvents(params) {
  const { data } = await request({
    url: eventsUrl,
    params,
  })
  return data
}
