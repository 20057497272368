import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { tryGetVictorina } from '../../../store/actions/app'
import { CustomField } from '../../CustomField/CustomField'
import { Button } from '../../Button/Button'
import { MONTHS } from '../../../services/constans'
import s from './VictorinaSelect.module.css'
import { Field, Form } from 'react-final-form'
import { dateHandler, days } from './helpers'

export const VictorinaSelect = () => {
  const [month, setMonth] = useState()
  const dispatch = useDispatch()

  const onSubmit = async data => {
    const errors = validate(data)
    if (errors) {
      return errors
    }
    try {
      await dispatch(tryGetVictorina(dateHandler(data)))
    } catch (e) {
      console.error(e)
    }
  }

  const validate = values => {
    const errors = {}
    if (!values.month) {
      errors.month = 'Заполните поле!'
    }
    if (!values.day) {
      errors.day = 'Заполните поле!'
    }
    return Object.keys(errors).length ? errors : undefined
  }
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={s.victorina__wrapper_block}>
          <div className={s.block}>
            <div className={s.block__text}>
              <h3>Какое научное событие произошло в твой день рождения?</h3>
              <p>
                Введи дату своего рождения и узнай, какое научное событие
                произошло в этот день
              </p>
            </div>
            <div className={s.block__footer}>
              <div className={s.wrapper_select}>
                <Field name={'month'}>
                  {props => (
                    <CustomField
                      type='select'
                      {...props.input}
                      options={MONTHS}
                      className={s.select}
                      defaultOption='Месяц'
                      classNameLabel={s.label_select}
                      classNameWrapper={s.field_select}
                      onChange={e => {
                        props.input.onChange(e)
                        setMonth(+e.target.value)
                      }}
                      {...props.meta}
                    />
                  )}
                </Field>
              </div>
              <div className={s.wrapper_select}>
                <Field name={'day'}>
                  {props => (
                    <>
                      <CustomField
                        type='select'
                        {...props.input}
                        options={days(month) || []}
                        className={s.select}
                        defaultOption='День'
                        classNameLabel={s.label_select}
                        classNameWrapper={s.field_select}
                        {...props.meta}
                      />
                    </>
                  )}
                </Field>
              </div>
              <div className={s.btn_wrapper}>
                <Button theme='primary' iconAfterText={true} type='submit'>
                  Вперед
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  )
}
