export const CATEGORIES = ['Мероприятия', 'Медиатека', 'Карта', 'Лекции']
export const TYPES = ['event', 'translation', ['event', 'translation']]

export const PHONE_MASK = '+7 (999) 999-99-99'

export const MONTHS = [
  {
    value: 1,
    label: 'Январь',
  },
  {
    value: 2,
    label: 'Февраль',
  },
  {
    value: 3,
    label: 'Март',
  },
  {
    value: 4,
    label: 'Апрель',
  },
  {
    value: 5,
    label: 'Май',
  },
  {
    value: 6,
    label: 'Июнь',
  },
  {
    value: 7,
    label: 'Июль',
  },
  {
    value: 8,
    label: 'Август',
  },
  {
    value: 9,
    label: 'Сентябрь',
  },
  {
    value: 10,
    label: 'Октябрь',
  },
  {
    value: 11,
    label: 'Ноябрь',
  },
  {
    value: 12,
    label: 'Декабрь',
  },
]

export const LECTURES = '1'
export const BROADCAST = '2'
export const QUIZ = '3'
export const EXCURSION = '4'

export const TRANSLATION_AUTH_MODAL =
  'Чтобы перейти к трансляции, необходимо авторизоваться'
export const EXCURSION_AUTH_MODAL =
  'Чтобы записаться на экскурсию, необходимо авторизоваться'
export const QUIZ_AUTH_MODAL =
  'Чтобы записаться на квиз, необходимо авторизоваться'

export const MAXIMUM_POSSIBLE_CATEGORY = 5
export const MINIMUM_POSSIBLE_CATEGORY = 1

export const MOBILE_WIDTH = 960

export const BASE_URL = process.env.GATSBY_APP_TARGET
export const EXCURSION_URL = process.env.GATSBY_APP_TARGET + '/api/excursions'
export const EVENTS_URL = process.env.GATSBY_APP_TARGET + '/api/events'

export const HEADINGS_URL = process.env.GATSBY_APP_TARGET + '/api/headings'
export const NEWS_URL = process.env.GATSBY_APP_TARGET + '/api/news'

export const PRIVACY_POLICY =
  'https://sfedu.sharepoint.com/:b:/s/pdn/EYNM707nO9RLnM6BEMfjHTcBlHnh_JCIxShzhs1YQmhjiQ'

export const SFEDU_LINK = 'http://sfedu.ru'
export const FESTIVAL_NAUKI_MAIL = 'festivalnauki@sfedu.ru'
export const SFEDU_FACEBOOK = 'https://www.facebook.com/groups/sfedu/'
export const SFEDU_INST = 'https://www.instagram.com/sfedu.ru/'
export const SFEDU_VK = 'http://vk.com/sfedu_official'
export const SFEDU_YOUTUBE = 'https://www.youtube.com/user/sfeduTV'
export const SFEDU_RUTUBE = 'https://rutube.ru/channel/19962412/'
export const SFEDU_YANDEXDZEN = 'https://zen.yandex.ru/sfedu'
export const SFEDU_CLASSMATES = 'https://ok.ru/sfedu.official'
export const SFEDU_TELEGRAM = 'https://t.me/sfedu_study'
export const MIN_VALUE = 10
export const FORMAT_DATE_WITH_TIME = 'DD MMM YYYY, HH:mm'
export const FORMAT_DATE_WITHOUT_TIME = 'DD MMM YYYY'
export const ZERO_STR = '0'
export const DEFAULT_FIELDNAMES_REG = [
  'status',
  'fullName',
  'email',
  'phone',
  'plainPassword',
  'repeatPassword',
]
export const GRADE_CLASS = 'gradeСlass'
export const GRADE_DEGREE = 'gradeDegree'
export const GRADE = 'grade'

export const STATUS_ERROR_403 = 'У вас нет прав на это действие'

const PROGRAM_LINK_DEV =
  'https://science-fest.dev2.webant.ru/uploads/2024-09-12/festival_15_program.pdf'
const PROGRAM_LINK_PROD =
  'https://festivalnauki.sfedu.ru/uploads/2024-09-11/festival_15_program.pdf'

export const PROGRAM_LINK =
  process.env.GATSBY_APP_ENV === 'development'
    ? PROGRAM_LINK_DEV
    : PROGRAM_LINK_PROD
