import React from 'react'
import { CurrentEventsCascad } from '../../cascad-screens/CurrentEventsCascad/CurrentEventsCascad'
import { Link } from '../Link'
import classes from './EventPage.module.css'
import { getDate } from '../../utils/getDate'
import { useInfiniteQuery } from 'react-query'
import { Button } from '../Button'

import { getImage } from '../../utils/getImage'
import getEvents from '../../utils/getEvents'
import { getEvent } from '../../api'

export const EventPage = () => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery('events', getEvents, {
    getNextPageParam: lastPage => lastPage.next,
  })

  return (
    <div className={classes.wrapper}>
      <span className={classes.heading}>Текущие мероприятия</span>
      {isLoading ? (
        <CurrentEventsCascad />
      ) : (
        <>
          <div className={classes.events}>
            {data?.pages?.map(({ results }) =>
              results.map(event => (
                <Link
                  to={`/category/${event.id}`}
                  className={classes.card}
                  key={event.id}
                >
                  <div className={classes.card__wrapper}>
                    <img
                      className={classes.card__cover}
                      src={getImage(event?.cover)}
                    />
                    <div className={classes.card__text_wrapper}>
                      <div className={classes.card__text}>
                        <div className={classes.title}>{event.name}</div>
                        {event?.annotation && (
                          <div
                            className={classes.annotation}
                            dangerouslySetInnerHTML={{
                              __html: event.annotation,
                            }}
                          ></div>
                        )}
                        {event?.broadcastDate && (
                          <div className={classes.date}>
                            {getDate(event.broadcastDate)}
                          </div>
                        )}
                        {event?.startDate && (
                          <div className={classes.date}>
                            {getDate(event.startDate)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            )}
          </div>
          {hasNextPage && (
            <Button
              className={classes.load_more_button}
              theme='secondary'
              onClick={fetchNextPage}
              disabled={isFetchingNextPage}
            >
              Показать еще
            </Button>
          )}
        </>
      )}
    </div>
  )
}
