import React from 'react'
import { Link } from 'gatsby'
import { useDispatch } from 'react-redux'
import ym from 'react-yandex-metrika'
import { Button, ModalItem } from '@/components'
import { openLoginModal } from '@/store/actions/modals'

import classes from './AuthModal.module.css'
import {
  EXCURSION_AUTH_MODAL,
  QUIZ_AUTH_MODAL,
  TRANSLATION_AUTH_MODAL,
} from '../../services/constans'

export const AuthModal = ({ isOpen, onToggle, type }) => {
  const dispatch = useDispatch()
  const handleRegClick = () => ym('reachGoal', 'OPEN_REGISTRATION')
  return (
    <ModalItem isOpen={isOpen} onClose={onToggle}>
      <div className={classes.modal}>
        <h2 className={classes.modal__title}>Войдите в аккаунт</h2>
        <p className={classes.modal__text}>
          {type === 'translation'
            ? TRANSLATION_AUTH_MODAL
            : type === 'quiz'
            ? QUIZ_AUTH_MODAL
            : EXCURSION_AUTH_MODAL}
        </p>
        <div className={classes.modal__buttons}>
          <div className={classes.login}>
            <Button
              onClick={() => {
                dispatch(openLoginModal())
                onToggle()
              }}
              theme='secondary'
            >
              Войти
            </Button>
          </div>
          <div className={classes.register}>
            <Link to='/registration/' onClick={handleRegClick}>
              <Button theme='primary'>Регистрация</Button>
            </Link>
          </div>
        </div>
      </div>
    </ModalItem>
  )
}
