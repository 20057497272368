import React from 'react'
import classes from './Radio.module.css'

export function Radio({ name, text, value, id, ...otherProps }) {
  return (
    <div>
      <input
        {...otherProps}
        className={classes.radio}
        type='radio'
        name={name}
        id={text}
        value={value}
      />
      <label className={classes.label} htmlFor={text}>
        {text}
      </label>
    </div>
  )
}
