import React from 'react'
import { useSelector } from 'react-redux'
import { useModalState } from '../../hooks/useModalState'
import { BigRightArrow } from '../../images/svg/Arrows'
import { CategoriesLayout } from '../../layouts/CategoriesLayout/CategoriesLayout'
import { AuthModal } from '../../modals/AuthModal/AuthModal'
import { Button } from '../Button'
import classes from './ContentQuiz.module.css'

export const ContentQuiz = ({ heading, entityId, EntityProfile }) => {
  const { isOpen, onToggle } = useModalState()
  const isAuth = useSelector(state => state.app.isAuth)

  return (
    <section className={classes.section_three}>
      <CategoriesLayout heading={heading} entityId={entityId}>
        <div className={classes.quiz_info}>
          <div className={classes.quiz_info__date}>
            <p className={classes.date}>
              Дата начала регистрации: <span>23 октября 8:00</span>
            </p>
            <p className={classes.date}>
              Окончание регистрации: <span>23 октября 20:00</span>
            </p>
            <p className={classes.date}>
              Количество мест ограничено, с каждым из зарегистрировавшихся
              свяжутся организаторы.
            </p>
          </div>

          <p className={classes.text}>
            Вас ждут 7 раундов с небольшими перерывами после 3 и 6 раундов.
          </p>
          <p className={classes.text}>
            Почти во всех раундах, за редким исключением, будет по 6 вопросов.
            После каждого вопроса вам в команде надо будет посовещаться и
            написать ответ на бланках, которые будут лежать где-то рядом. После
            каждого раунда надо сдать бланк.
          </p>
          <p className={classes.text}>
            Если у вас будут появляться вопросы перед игрой или в течение ее, не
            стесняйтесь задавать их нашим квизменам (это такие заботливые
            молодые ребята в фирменных футболках с лого “Квиз, плиз!”).
          </p>
        </div>
        <div className={classes.quiz__button}>
          <Button
            theme='primary'
            iconAfterText={true}
            customIcon={<BigRightArrow />}
            className={classes.button}
            onClick={() =>
              isAuth
                ? window.open('https://forms.gle/So4kjD2KvrhVGG9C9')
                : onToggle()
            }
          >
            Перейти к квизу
          </Button>
          <AuthModal isOpen={isOpen} onToggle={onToggle} type='quiz' />
        </div>
        <div className={classes.other_events}>
          <EntityProfile />
        </div>
      </CategoriesLayout>
    </section>
  )
}
