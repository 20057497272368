import React from 'react'

export const RightArrow = props => (
  <svg
    width={21}
    height={12}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M1 6.167h18M15 1.5l4.667 4.667L15 10.833'
      stroke='#fff'
      strokeWidth={1.333}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const BigRightArrow = props => (
  <svg
    width={26}
    height={22}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.543 6.269V1.842l10.487 8.924-10.487 8.923v-5.161l-.77.02c-2.648.071-4.718.454-6.403 1.231h0c-1.828.843-3.133 2.097-4.474 3.627h0l-.333.38v-.392c0-3.649.822-6.468 2.358-8.464 1.841-2.392 4.784-3.744 8.903-3.912l.72-.03v-.72z'
      stroke='#fff'
      strokeWidth={1.5}
    />
  </svg>
)

export const PlayVideoArrow = props => (
  <svg
    width={12}
    height={14}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.887 6.351L1.134.545A.75.75 0 000 1.19v11.38a.75.75 0 001.122.65l9.754-5.573a.75.75 0 00.011-1.296zM1 12.138V1.63l9.008 5.361L1 12.138z'
      fill='#fff'
    />
  </svg>
)
export const BtnIcon = props => (
  <svg
    width='21'
    height='11'
    viewBox='0 0 21 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M20 5.66666H2'
      stroke='white'
      stroke-width='1.33333'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M6 1L1.33333 5.66667L6 10.3333'
      stroke='white'
      stroke-width='1.33333'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
)
