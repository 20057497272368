import React from 'react'
import cn from 'classnames'
import classes from './CustomField.module.css'
import InputMask from 'react-input-mask'
import { PHONE_MASK } from '../../services/constans'

export function CustomField(props) {
  const {
    className,
    required,
    type,
    options,
    name,
    label,
    error,
    touched,
    submitError,
    defaultOption,
    classNameLabel,
    classNameWrapper,
    onChange,
    ...otherProps
  } = props
  return (
    <div className={cn(classes.field, classNameWrapper)}>
      <label className={cn(classes.label, classNameLabel)} htmlFor={name}>
        {label}
        {required && '*'}
      </label>
      {type === 'select' ? (
        <select
          {...otherProps}
          onChange={onChange}
          id={name}
          className={cn(classes.select, className, {
            [classes.errorInput]: submitError,
          })}
        >
          <option value='' disabled selected>
            {defaultOption || 'Выберите'}
          </option>
          {options.map(option => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      ) : type === 'tel' ? (
        <InputMask {...otherProps} mask={PHONE_MASK} onChange={onChange}>
          {inputProps => (
            <input
              {...inputProps}
              className={cn(
                classes.input,
                ((error && touched) || submitError) && classes.errorInput
              )}
            />
          )}
        </InputMask>
      ) : (
        <input
          {...otherProps}
          type={type}
          onChange={onChange}
          className={cn(
            classes.input,
            ((error && touched) || submitError) && classes.errorInput
          )}
        />
      )}
      <span
        className={classes.error}
        style={(error && touched) || submitError ? {} : { display: 'none' }}
      >
        {error || submitError}
      </span>
    </div>
  )
}
