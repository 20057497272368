import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeVictorinaInfo } from '../../../store/actions/app'
import { getDate } from '../../../utils/getDate'
import { Button } from '../../Button/Button'
import s from './VictorinaAnswer.module.css'

export const VictorinaAnswer = () => {
  const { items } = useSelector(state => state.app.infoVictorina)
  const dispatch = useDispatch()
  const item = items.length && items[0]
  return (
    <div className={s.wrapper_block}>
      <div className={s.block}>
        <div className={s.text}>
          <h3>{item.date && getDate(item.date, false)}</h3>
          <p>
            {item.textDescription ||
              'Открытий в этот день еще не произошло, вы можете быть первым :)'}
          </p>
        </div>
        <div className={s.btn}>
          <Button
            className={s.customBtn}
            theme='primary'
            iconAfterText={true}
            onClick={() => dispatch(closeVictorinaInfo())}
          >
            <span> Еще раз </span>
          </Button>
        </div>
      </div>
    </div>
  )
}
