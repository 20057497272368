import React from 'react'

export const CloseSvg = props => (
  <svg
    width={18}
    height={18}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M17 1L1 17M1 1l16 16'
      stroke='#fff'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
