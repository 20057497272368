import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

export const Link = ({ to, children, className, styles }) => (
  <GatsbyLink
    to={to}
    style={{ ...styles, textDecoration: 'none' }}
    className={className}
  >
    {children}
  </GatsbyLink>
)
