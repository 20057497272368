import React from 'react'
import classes from './ContentLectures.module.css'
import { CategoriesLayout } from '../../layouts/CategoriesLayout/CategoriesLayout'
import { LectureCard } from '../LectureCard'

export const ContentLectures = ({ heading, lectures, entityId }) => {
  return (
    !!lectures?.length && (
      <section className={classes.sectionThree}>
        <CategoriesLayout heading={heading} entityId={entityId}>
          <div className={classes.lectures}>
            {lectures?.map(({ results }) =>
              results.map((lecture, index) => (
                <LectureCard
                  key={lecture.id}
                  lecture={lecture}
                  checkIsLast={index === lectures.length - 1}
                />
              ))
            )}
          </div>
        </CategoriesLayout>
      </section>
    )
  )
}
