import React from 'react'
import classes from './Organizers.module.css'
import { OrganizerCard } from './OrganizerCard'
import { MOCK_ORGANIZERS } from './helpers.jsx'

export const Organizers = () => {
  return (
    <section className={classes.organizers}>
      <h2 className={classes.organizes__title}>Приветствия участникам</h2>
      <div className={classes.organizers__inner}>
        {MOCK_ORGANIZERS.map(organizer => (
          <OrganizerCard organizer={organizer} key={organizer.key} />
        ))}
      </div>
    </section>
  )
}
