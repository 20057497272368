import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openLoginModal } from '@/store/actions/modals'
import { Link } from 'gatsby'
import { Button } from '@/components'
import ym from 'react-yandex-metrika'
import classes from '../Header.module.css'
import { PROGRAM_LINK } from '../../../services/constans'

export const HeaderButtons = () => {
  const isAuth = useSelector(state => state.app.isAuth)
  const dispatch = useDispatch()
  const handleRegClick = () => ym('reachGoal', 'OPEN_REGISTRATION')
  return isAuth ? (
    <>
      <div className={classes.program}>
        <Link target='_blank' to={PROGRAM_LINK}>
          <Button theme='transparent'>ПРОГРАММА</Button>
        </Link>
      </div>
      <div className={classes.profile}>
        <Link to='/profile/'>
          <Button theme='primary'>Профиль</Button>
        </Link>
      </div>
    </>
  ) : (
    <>
      <div className={classes.program}>
        <Link target='_blank' to={PROGRAM_LINK}>
          <Button theme='transparent'>ПРОГРАММА</Button>
        </Link>
      </div>
      <div className={classes.right}>
        <Link to='/registration/' onClick={handleRegClick}>
          <Button theme='primary'>Регистрация</Button>
        </Link>
      </div>
      <div className={classes.left}>
        <Button
          onClick={() => {
            dispatch(openLoginModal())
          }}
          theme='default'
        >
          Войти
        </Button>
      </div>
    </>
  )
}
