import React, { useRef, useCallback } from 'react'

export const Iframe = props => {
  const iframeRef = useRef(null)

  const iframeCallbackRef = useCallback(node => {
    iframeRef.current = node
  }, [])

  // TODO: May be needed in the future

  // useEffect(() => {
  //   const onBlur = () => {
  //     if (
  //       document.activeElement &&
  //       document.activeElement.nodeName.toLowerCase() === 'iframe' &&
  //       iframeRef.current &&
  //       iframeRef.current === document.activeElement
  //     ) {
  //       props.onInferredClick(iframeRef.current)
  //     }
  //   }

  // window.addEventListener('blur', onBlur)

  // return () => {
  //   window.removeEventListener('blur', onBlur)
  // }
  // }, [])

  return <iframe {...props} ref={iframeCallbackRef} />
}
