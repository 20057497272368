import React from 'react'
import { Link } from 'gatsby'
import { getDate } from '../../utils/getDate'
import { getImage } from '../../utils/getImage'
import classes from './EventCard.module.css'

export const EventCard = ({ isLast, event }) => {
  return (
    <Link
      to={`/category/${event?.id}`}
      style={{
        textDecoration: 'none',
      }}
      className={isLast ? classes.card_last : classes.card}
    >
      <div className={classes.card__wrapper}>
        <img className={classes.card__cover} src={getImage(event?.cover)} />
        <div className={classes.card__text_wrapper}>
          <div className={classes.card__text}>
            <div className={classes.title}>{event.name}</div>
            {event?.annotation && (
              <div
                className={classes.annotation}
                dangerouslySetInnerHTML={{
                  __html: event.annotation,
                }}
              ></div>
            )}
            {event?.broadcastDate && (
              <div className={classes.date}>{getDate(event.broadcastDate)}</div>
            )}
            {event?.startDate && (
              <div className={classes.date}>{getDate(event.startDate)}</div>
            )}
          </div>
        </div>
      </div>
    </Link>
  )
}
