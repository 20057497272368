import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import {
  FORMAT_DATE_WITHOUT_TIME,
  FORMAT_DATE_WITH_TIME,
} from '../services/constans'

export function getDate(date, withTime = true) {
  return dayjs(date)
    .locale('ru')
    .format(withTime ? FORMAT_DATE_WITH_TIME : FORMAT_DATE_WITHOUT_TIME)
}

export function getDatePhoto(date, withTime = true) {
  return dayjs(date)
    .locale('ru')
    .format(withTime && FORMAT_DATE_WITHOUT_TIME)
}
