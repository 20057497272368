import centerInvestLogo from '@/images/partnersIcon/center-invest-logo.svg'
import minObrNaukiLogo from '@/images/partnersIcon/min-obr-nauki-logo.svg'
import naukaLogo from '@/images/partnersIcon/nauka-logo.svg'
import roZnanieLogo from '@/images/partnersIcon/ro-znanie-logo.svg'
import souzeMashLogo from '@/images/partnersIcon/souze-mash-logo.svg'
import weTogetherLogo from '@/images/partnersIcon/we-together-logo.svg'
import yfuLogo from '@/images/partnersIcon/yfu-logo.svg'
import tenYearsLogo from '@/images/partnersIcon/ten-years-nauki-and-texnologi.svg'
import mguLogo from '@/images/partnersIcon/mgu.svg'
import rostovAnniversaryLogo from '@/images/partnersIcon/275-years.svg'
import rbkLogo from '@/images/partnersIcon/rbk.svg'
import businessNewspaperLogo from '@/images/partnersIcon/businessNewspaper.svg'

export const sponsorIcons = [
  {
    src: minObrNaukiLogo,
    alt: 'Мин Обр Науки',
    title: 'Мин Обр Науки',
  },
  {
    src: tenYearsLogo,
    alt: 'Десятилетие науки и технологий',
    title: 'Десятилетие науки и технологий',
  },
  {
    src: naukaLogo,
    alt: 'Наука',
    title: 'Наука',
  },
  {
    src: mguLogo,
    alt: 'МГУ',
    title: 'МГУ',
  },
  {
    src: yfuLogo,
    alt: 'ЮФУ',
    title: 'ЮФУ',
  },
  {
    src: centerInvestLogo,
    alt: 'Центр Инвест',
    title: 'Центр Инвест',
  },
  {
    src: roZnanieLogo,
    alt: 'РО знание',
    title: 'РО знание',
  },
  {
    src: rostovAnniversaryLogo,
    alt: '275 лет Ростову-на-Дону',
    title: '275 лет Ростову-на-Дону',
  },
  {
    src: souzeMashLogo,
    alt: 'Союз Машиностроителей',
    title: 'Союз Машиностроителей',
  },
  {
    src: rbkLogo,
    alt: 'РБК',
    title: 'РБК',
  },
  {
    src: businessNewspaperLogo,
    alt: 'Деловая газета.юг',
    title: 'Деловая газета.юг',
  },
  {
    src: weTogetherLogo,
    alt: 'Мы вместе',
    title: 'Мы вместе',
  },
]
