import React from 'react'
import { Link } from '../Link'
import classes from './Categories.module.css'
import { Button } from '@/components/Button/Button'
import cn from 'classnames'

export const Categories = () => (
  <section className={classes.section}>
    <div className={classes.container}>
      <div className={classes.cards}>
        <Link to='/categories/2' className={cn(classes.card, classes.events)}>
          <div className={classes.title}>Программа мероприятий</div>
          <div className={classes.button}>
            <Button theme='transparent' iconAfterText>
              Перейти
            </Button>
          </div>
        </Link>
        <Link
          to='/medialibrary/'
          className={cn(classes.card, classes.medialibrary)}
        >
          <div className={classes.title}>Медиатека</div>
          <div className={classes.button}>
            <Button theme='transparent' iconAfterText>
              Перейти
            </Button>
          </div>
        </Link>
        <Link to='/headings/' className={cn(classes.card, classes.headings)}>
          <div className={classes.title}>Новости</div>
          <div className={classes.button}>
            <Button
              theme='transparent'
              iconAfterText={true}
              arrowTheme={'dark'}
            >
              Перейти
            </Button>
          </div>
        </Link>
      </div>
    </div>
  </section>
)
