import React from 'react'
import classes from './Content.module.css'
import { EventCard } from '../EventCard/EventCard'
import { CategoriesLayout } from '../../layouts/CategoriesLayout/CategoriesLayout'

export const Content = ({ heading, events, entityId }) => {
  return (
    !!events?.length && (
      <section className={classes.sectionThree}>
        <CategoriesLayout heading={heading} entityId={entityId}>
          <div className={classes.events}>
            {events?.map(({ results }) =>
              results.map((event, index) => (
                <EventCard
                  key={event.id}
                  event={event}
                  isLast={index === events.length - 1}
                />
              ))
            )}
          </div>
        </CategoriesLayout>
      </section>
    )
  )
}
