import React from 'react'
import classes from './CategoriesLayout.module.css'

export const CategoriesLayout = ({
  children,
  heading,
  hasHeader = true,
  isNews,
}) => (
  <div className={classes.container}>
    {hasHeader && (
      <div
        className={isNews ? classes.sectionHeaderNews : classes.sectionHeader}
      >
        <h2 className={classes.title}>{heading}</h2>
      </div>
    )}
    <div className={classes.container__inner}>{children}</div>
  </div>
)
