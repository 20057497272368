import React from 'react'
import { LecturePage } from '../LecturePage/LecturePage'
import { ContentQuiz } from '../ContentQuiz/ContentQuiz'
import { ContentLectures } from '../ContentLectures/ContentLectures'

export const LecturesMedia = ({ heading, lectures, categoryId, isQuiz }) =>
  isQuiz ? (
    <ContentQuiz
      heading={heading}
      entityId={categoryId}
      EntityProfile={LecturePage}
    />
  ) : (
    <ContentLectures
      heading={heading}
      entityId={categoryId}
      lectures={lectures}
    />
  )
