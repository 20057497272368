import React from 'react'
import PropTypes from 'prop-types'
import classes from './Header.module.css'
import logo from '@/images/headerLogo.svg'
import { Button } from '@/components'
import { Link } from 'gatsby'
import { useModalState } from '../../hooks'
import { MenuButton } from './MenuButton/MenuButton'
import { HeaderButtons } from './HeaderButtons'

const Header = props => {
  const { isOpen, onToggle } = useModalState()

  return (
    <>
      <div
        className={classes.header}
        style={{
          backgroundColor: `rgba(0, 0, 0, ${props.onMain ? '.0' : '1'})`,
          position: props.onMain ? 'absolute' : 'initial',
        }}
      >
        <div className={classes.container}>
          <div className={classes.logo}>
            <Link to='/'>
              <img alt='логотип' src={logo} />
            </Link>
          </div>
          <div className={classes.buttons}>
            <HeaderButtons />
          </div>
          <div onClick={onToggle} className={classes.mobile_buttons}>
            <MenuButton isOpen={isOpen}>
              <div className={classes.mobile__modal__wrapper}>
                <div className={classes.mobile__modal}>
                  <HeaderButtons />
                  <div className={classes.mobile__route_buttons}>
                    <Link to='/'>
                      <Button theme='secondary'>Главная</Button>
                    </Link>
                    <Link to='/categories/2'>
                      <Button theme='secondary'>Мероприятия</Button>
                    </Link>
                    <Link to='/medialibrary/'>
                      <Button theme='secondary'>Медиатека</Button>
                    </Link>
                    <Link to='/headings/'>
                      <Button theme='secondary'>Новости</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </MenuButton>
          </div>
        </div>
      </div>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
