import React from 'react'
import classes from './Sponsors.module.css'
import { sponsorIcons } from './sponsorsIcon'

export const Sponsors = () => (
  <div className={classes.sponsors}>
    <div className={classes.container}>
      <div className={classes.sponsorsTitle}>Наши партнеры</div>
      <div className={classes.sponsorsItems}>
        {sponsorIcons.map(sponsorIcon => (
          <img
            src={sponsorIcon.src}
            alt={sponsorIcon.alt}
            title={sponsorIcon.title}
          />
        ))}
      </div>
    </div>
  </div>
)
