const watchVideoMainPageLinkDev =
  'https://science-fest.dev2.webant.ru/uploads/2024-09-04/d7d62f9d58b696e77f802c9935bde3ab60914df6.webm'
const watchVideoMainPageLinkDevMP4 =
  'https://science-fest.dev2.webant.ru/uploads/2024-09-04/9eb533edc6eb226135ef55500d1686a02c3da770.mp4'

const watchVideoMainPageLinkProd =
  'https://festivalnauki.sfedu.ru/uploads/15_science_festival_2024_3_2.webm'

const watchVideoMainPageLinkProdMP4 =
  'https://festivalnauki.sfedu.ru/uploads/15_science_festival_2024_3_2.mp4'

export const watchVideoMainPageLink =
  process.env.GATSBY_APP_ENV === 'development'
    ? watchVideoMainPageLinkDev
    : watchVideoMainPageLinkProd

export const watchVideoMainPageLinkMP4 =
  process.env.GATSBY_APP_ENV === 'development'
    ? watchVideoMainPageLinkDevMP4
    : watchVideoMainPageLinkProdMP4
// TODO: Ссылки на видео изменятся, когда заказщик предоставит сами видео.
export const backgroundMainPageVideoLink =
  'https://science-fest.dev2.webant.ru/uploads/2022-09-12/d1e88853bbd245e34c6a9e7018b1d534.mp4'
