import React from 'react'
import { ModalItem } from '@/components'
import { useSelector, useDispatch } from 'react-redux'
import classes from './RegModal.module.css'
import { Link } from 'gatsby'
import { Button } from '@/components'
import { closeRegModal, openLoginModal } from '@/store/actions/modals'

export function RegModal() {
  const { isRegModalOpen } = useSelector(state => state.modals)
  const dispatch = useDispatch()
  return (
    <ModalItem
      withBackgroundBlock
      isOpen={isRegModalOpen}
      onClose={() => {
        dispatch(closeRegModal())
      }}
    >
      <h1 className={classes.title}>Регистрация</h1>
      <div className={classes.subtitle}>Выберите статус:</div>
      <div className={classes.item}>
        <Link to='/registration/?status=2'>
          <Button
            onClick={() => {
              dispatch(closeRegModal())
            }}
            theme='status'
            className={classes.button}
          >
            Школьник
          </Button>
        </Link>
      </div>
      <div className={classes.item}>
        <Link to='/registration/?status=3'>
          <Button
            onClick={() => {
              dispatch(closeRegModal())
            }}
            theme='status'
            className={classes.button}
          >
            Студент
          </Button>
        </Link>
      </div>
      <div className={classes.item}>
        <Link to='/registration/?status=4'>
          <Button
            onClick={() => {
              dispatch(closeRegModal())
            }}
            theme='status'
            className={classes.button}
          >
            Родитель
          </Button>
        </Link>
      </div>
      <div className={classes.item}>
        <Link to='/registration/?status=5'>
          <Button
            onClick={() => {
              dispatch(closeRegModal())
            }}
            theme='status'
            className={classes.button}
          >
            Специалист
          </Button>
        </Link>
      </div>
      <div className={classes.item}>
        <Link to='/registration/?status=6'>
          <Button
            onClick={() => {
              dispatch(closeRegModal())
            }}
            theme='status'
            className={classes.button}
          >
            Другое
          </Button>
        </Link>
      </div>
      <div className={classes.autorized}>
        Уже есть аккаунт?{' '}
        <span
          onClick={() => {
            dispatch(openLoginModal())
          }}
        >
          Авторизуйтесь
        </span>
      </div>
      <div className={classes.description}>
        *Без регистрации вам будет недоступно получение сертификата об участии в
        фестивале
      </div>
    </ModalItem>
  )
}
