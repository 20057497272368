import React from 'react'
import classes from '../Organizers.module.css'
import { Button } from '@/components/Button/Button'
import { useModalState } from '@/hooks/useModalState'
import { ModalItem } from '@/components'

export const OrganizerCard = ({ organizer }) => {
  const { isOpen, onToggle } = useModalState()
  return (
    <div className={classes.organizer}>
      <div className={classes.organizer__wrapper_photo}>
        <img
          src={organizer.photo}
          alt='Фото'
          className={classes.organizer__photo}
        />
      </div>
      <div className={classes.organizer__text_block}>
        <div className={classes.organizer__info}>
          <p className={classes.organizer__info_name}>{organizer.name}</p>
          <p className={classes.organizer__info_position}>
            {organizer.position}
          </p>
        </div>
        <div className={classes.organizer__button} onClick={onToggle}>
          <Button
            className={classes.organizer__open_button}
            theme='transparent'
          >
            Открыть обращение
          </Button>
        </div>
      </div>
      <div className={classes.modal}>
        <ModalItem isOpen={isOpen} onClose={onToggle}>
          <div className={classes.organizer__modal}>
            <div className={classes.modal__appeal}>
              <h3 className={classes.modal__position}>
                {organizer.modal?.modalPosition}
              </h3>
              <p className={classes.modal__name}>
                {organizer.modal?.modalName}
              </p>
              <div className={classes.modal__video}>
                <iframe
                  className={classes.video}
                  src={organizer.modal.modalVideo}
                  frameBorder='0'
                  allowFullscreen='allowfullscreen'
                  mozallowfullscreen='mozallowfullscreen'
                  msallowfullscreen='msallowfullscreen'
                  oallowfullscreen='oallowfullscreen'
                  webkitallowfullscreen='webkitallowfullscreen'
                />
              </div>
            </div>
          </div>
        </ModalItem>
      </div>
    </div>
  )
}
