import React, { useRef } from 'react'
import { ModalItem } from '@/components'
import { closeVideoModal } from '@/store/actions/modals'
import { useSelector, useDispatch } from 'react-redux'
import { openRegModal } from '@/store/actions/modals'
import classes from './VideoModal.module.css'
import closeCircleImg from '@/images/close-circle.svg'
import {
  watchVideoMainPageLink,
  watchVideoMainPageLinkMP4,
} from '../../constants/video-links'

export function VideoModal() {
  const { isVideoModalOpen } = useSelector(state => state.modals)
  const { isAuth } = useSelector(state => state.app)
  const dispatch = useDispatch()
  const videoRef = useRef(null)

  function onEnded() {
    if (!isAuth) {
      dispatch(openRegModal())
    }
  }

  function onClose() {
    dispatch(closeVideoModal())
    stopVideo()
  }

  function stopVideo() {
    videoRef.current.pause()
    videoRef.current.currentTime = 0
  }

  return (
    <ModalItem
      className={classes.wrapper}
      isOpen={isVideoModalOpen}
      customCloseButton={
        <div className={classes.close} onClick={onClose}>
          <img src={closeCircleImg} alt='close-btn' />
        </div>
      }
    >
      <video
        loop='loop'
        autoplay='autoplay'
        controls='controls'
        onEnded={onEnded}
        className={classes.video}
        ref={videoRef}
        playsinline='playsinline'
      >
        <source src={watchVideoMainPageLink} type='video/webm' />
        {/* Safari не поддреживает webm-формат */}
        <source src={watchVideoMainPageLinkMP4} type='video/mp4' />
      </video>
    </ModalItem>
  )
}
