import * as React from 'react'

export const CheckIcon = props => (
  <svg
    width={15}
    height={12}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M4.771 8.906L1.433 5.568 0 7.001l4.771 4.771 9.839-9.839L13.177.5 4.77 8.906z'
      fill='#00A3E1'
    />
  </svg>
)
