import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import classes from './CurrentEventsCascad.module.css'

export function CurrentEventsCascad() {
  return (
    <SkeletonTheme>
      <div className={classes.card}>
        <Skeleton width='100%' height='100%' />
      </div>
      <div className={classes.card}>
        <Skeleton width='100%' height='100%' />
      </div>
      <div className={classes.card}>
        <Skeleton width='100%' height='100%' />
      </div>
      <div className={classes.card}>
        <Skeleton width='100%' height='100%' />
      </div>
    </SkeletonTheme>
  )
}
