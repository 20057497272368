import React from 'react'
import classes from './MenuButton.module.css'
import cn from 'classnames'

export const MenuButton = ({ children, className, isOpen }) => (
  <>
    <div
      className={cn(classes.header__menu, className, {
        [classes.active]: isOpen,
      })}
    >
      <div className={classes.menu__bar_1}></div>
      <div className={classes.menu__bar_2}></div>
      <div className={classes.menu__bar_3}></div>
    </div>
    {isOpen && children}
  </>
)
