import React, { useEffect } from 'react'
import cn from 'classnames'
import classes from './ModalItem.module.css'
import logo from '@/images/modalLogo.svg'
import { CloseSvg } from '../../images/svg/Close'

export function ModalItem(props) {
  const {
    withBackgroundBlock,
    children,
    className,
    isOpen,
    onClose,
    customCloseButton,
  } = props
  useEffect(() => {
    if (isOpen) {
      document.querySelector('html').style.overflowY = 'hidden'
    } else {
      document.querySelector('html').style.overflowY = 'scroll'
    }

    return () => {
      document.querySelector('html').style.overflowY = 'scroll'
    }
  }, [isOpen])

  if (!isOpen) {
    return null
  }
  return (
    <div className={classes.modal}>
      <div className={classes.modalDialog}>
        <div className={classes.modalContent}>
          {withBackgroundBlock && (
            <div className={classes.leftBlock}>
              <div className={classes.leftContainer}>
                <div className={classes.leftBlockTitle}>
                  <div>
                    <img alt='Лого' src={logo} />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={cn(classes.rightBlock, className)}>
            {customCloseButton || (
              <div className={classes.exit} onClick={onClose}>
                <CloseSvg />
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
