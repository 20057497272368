import avatar3 from '@/images/avatar3.png'
import avatar7 from '@/images/avatar7.png'
import avatar5 from '@/images/avatar5.png'

export const MOCK_ORGANIZERS = [
  {
    key: 1,
    name: 'Инна Шевченко',
    position: 'Ректор Южного федерального университета',
    photo: avatar3,
    modal: {
      modalPosition: 'Ректор Южного федерального университета',
      modalName: 'Шевченко Инна',
      modalVideo:
        'https://vk.com/video_ext.php?oid=-30635885&id=456239058&hd=2&hash=056b622100893f6d&autoplay=0',
    },
  },
  {
    key: 2,
    name: 'Марина Боровская',
    position:
      'Президент Южного федерального университета, председатель Совета ректоров вузов Юга России',
    photo: avatar7,
    modal: {
      modalPosition:
        'Президент Южного федерального университета, председатель Совета ректоров вузов Юга России',
      modalName: 'Марина Боровская',
      modalVideo:
        'https://vk.com/video_ext.php?oid=-30635885&id=456239059&hd=2&hash=370ca97ced140269&autoplay=0',
    },
  },
  {
    key: 3,
    name: 'Василий Высоков',
    position:
      'Председатель Совета директоров банка "Центр-инвест", доктор экономических наук, профессор',
    photo: avatar5,
    modal: {
      modalPosition:
        'Председатель Совета директоров банка "Центр-инвест", доктор экономических наук, профессор',
      modalName: 'Василий Высоков',
      modalVideo:
        'https://vk.com/video_ext.php?oid=-30635885&id=456239060&hd=2&hash=c9c6ae38ef6d722e&autoplay=0',
    },
  },
]
