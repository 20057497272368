import React, { useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form'
import { useSelector, useDispatch } from 'react-redux'
import { ModalItem } from '@/components'
import formClasses from '@/components/css/Form.module.css'
import classes from './LoginModal.module.css'
import { closeLoginModal } from '@/store/actions/modals'
import { tryLogin, resetAuthError } from '@/store/actions/app'
import { Button, CustomField } from '@/components'

export function LoginModal() {
  const { isLoginModalOpen } = useSelector(state => state.modals)
  const { isAuth, hasAuthError } = useSelector(state => state.app)
  const [disabled, setDisabled] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isAuth) {
      onClose()
    }
  }, [isAuth])

  function onSubmit(data) {
    const errors = validate(data)
    if (errors) {
      return errors
    }
    setDisabled(true)
    dispatch(tryLogin(data))
    setDisabled(false)
  }

  function validate(values) {
    const errors = {}
    if (!values.username?.trim()) {
      errors.username = 'Необходимо заполнить это поле'
    } else {
      const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      if (!regex.test(values.username.trim())) {
        errors.username = 'Это не похоже на настоящий email'
      }
    }
    if (!values.password?.trim()) {
      errors.password = 'Необходимо заполнить это поле'
    }
    return Object.keys(errors).length ? errors : undefined
  }

  function onClose() {
    dispatch(closeLoginModal())
    dispatch(resetAuthError())
  }

  return (
    <ModalItem withBackgroundBlock isOpen={isLoginModalOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <h1 className={formClasses.h1}>Войти</h1>
            <p className={classes.error}>
              {hasAuthError && 'Неверный email или пароль'}
            </p>
            <div className={classes.inputWrapper}>
              <Field name='username'>
                {props => (
                  <CustomField
                    {...props.input}
                    {...props.meta}
                    label='E-mail'
                  />
                )}
              </Field>
            </div>
            <div className={classes.inputWrapper}>
              <Field name='password'>
                {props => (
                  <CustomField
                    {...props.input}
                    {...props.meta}
                    type='password'
                    label='Пароль'
                  />
                )}
              </Field>
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                size='large'
                theme='primary'
                disabled={disabled}
                type='submit'
              >
                Войти
              </Button>
            </div>
          </form>
        )}
      />
    </ModalItem>
  )
}
