import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ModalItem } from '@/components'
import { closeLogoutModal } from '@/store/actions/modals'
import { Button } from '@/components'
import { logout } from '@/store/actions/app'
import classes from './LogoutModal.module.css'

export function LogoutModal() {
  const { isLogoutModalOpen } = useSelector(state => state.modals)
  const dispatch = useDispatch()

  function onClose() {
    dispatch(closeLogoutModal())
  }

  function onLogout() {
    dispatch(closeLogoutModal())
    dispatch(logout())
  }

  return (
    <ModalItem isOpen={isLogoutModalOpen} onClose={onClose}>
      <div className={classes.wrapper}>
        <h1 className={classes.title}>Выйти из аккаунта</h1>
        <p className={classes.subtitle}>
          Вы уверены, что хотите выйти из аккаунта?
        </p>
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            onClick={onClose}
            size='large'
            theme='secondary'
          >
            Отмена
          </Button>
          <Button
            className={classes.button}
            onClick={onLogout}
            size='large'
            theme='primary'
          >
            Выйти
          </Button>
        </div>
      </div>
    </ModalItem>
  )
}
