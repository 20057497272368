import request from '@/services/request'
import { EVENTS_URL } from '../services/constans'

export const getMediaQuery = params => {
  return request({
    url: `https://festivalnauki.sfedu.ru/api/media`,
    method: 'get',
    params,
  })
}

export const getMediaLecture = (params, id) => {
  return request({
    url: `${process.env.GATSBY_APP_TARGET}/api/media/${id}`,
    method: 'get',
    params,
  })
}

export const getCategoryEvents = params => {
  return request({
    url: `${EVENTS_URL}`,
    method: 'get',
    params,
  })
}

export const getEvent = (params, id) => {
  return request({
    url: `${EVENTS_URL}/${id}`,
    method: 'get',
    params,
  })
}
