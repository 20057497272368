import React from 'react'
import { ContentQuiz } from '../ContentQuiz/ContentQuiz'
import { Content } from '../Content/Content'
import { EventPage } from '../EventPage/EventPage'

export const Events = ({ heading, events, categoryId, isQuiz }) =>
  isQuiz ? (
    <ContentQuiz
      heading={heading}
      entityId={categoryId}
      EntityProfile={EventPage}
    />
  ) : (
    <Content heading={heading} entityId={categoryId} events={events} />
  )
