import { getMediaQuery } from '../api/media'

const getLectures = async ({ pageParam = 1 }) => {
  const res = await getMediaQuery({ type: 'lecture' })

  const data = {
    results: res.data.items,
    next: res.data.countOfPages === pageParam ? undefined : pageParam + 1,
  }
  return data
}
export default getLectures
