import React from 'react'
import { Link } from 'gatsby'
import { getDate } from '../../utils/getDate'
import { getImage } from '../../utils/getImage'
import classes from './LectureCard.module.css'

export const LectureCard = ({ checkIsLast, lecture }) => {
  return (
    <Link
      to={`/lecture/${lecture?.id}`}
      style={{
        textDecoration: 'none',
      }}
      className={checkIsLast ? classes.card_last : classes.card}
    >
      <div className={classes.card__wrapper}>
        <img className={classes.card__cover} src={getImage(lecture?.cover)} />
        <div className={classes.card__text_wrapper}>
          <div className={classes.card__text}>
            <div className={classes.title}>{lecture.name}</div>
            {lecture?.description && (
              <div
                className={classes.description}
                dangerouslySetInnerHTML={{
                  __html: lecture.description,
                }}
              ></div>
            )}
            {lecture?.broadcastDate && (
              <div className={classes.date}>
                {getDate(lecture.broadcastDate)}
              </div>
            )}
            {lecture?.startDate && (
              <div className={classes.date}>{getDate(lecture.startDate)}</div>
            )}
          </div>
        </div>
      </div>
    </Link>
  )
}
