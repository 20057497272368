import React from 'react'

export const Facebook = props => (
  <a
    href='https://www.facebook.com/groups/sfedu/'
    target='_blank'
    name='facebook'
  >
    <svg
      width={29}
      height={29}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx={14.5} cy={14.5} r={14.5} fill='#fff' />
      <path
        d='M12.297 24v-8.446H10v-3.041h2.297V9.915c0-2.04 1.358-3.915 4.485-3.915 1.266 0 2.202.118 2.202.118l-.074 2.84s-.954-.01-1.996-.01c-1.128 0-1.309.506-1.309 1.344v2.22H19l-.148 3.042h-3.247V24h-3.308z'
        fill='#000'
      />
    </svg>
  </a>
)

export const Insta = props => (
  <a
    href='https://www.instagram.com/sfedu.ru/'
    target='_blank'
    name='instagramm'
  >
    <svg
      width={29}
      height={29}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx={14.5} cy={14.5} r={14.5} fill='#fff' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.935 7.581c-.897-.041-1.165-.05-3.435-.05s-2.538.01-3.435.05c-.829.038-1.279.176-1.578.293a2.64 2.64 0 00-.978.635c-.282.274-.499.608-.635.978-.117.3-.255.75-.293 1.578-.041.897-.05 1.165-.05 3.435s.01 2.538.05 3.435c.038.829.176 1.279.293 1.579.136.368.353.703.635.977.274.282.609.5.978.635.3.117.75.255 1.578.293.897.041 1.165.05 3.435.05s2.538-.009 3.435-.05c.829-.038 1.279-.176 1.579-.293.396-.154.68-.338.977-.635.282-.274.5-.609.635-.977.117-.3.255-.75.293-1.58.041-.896.05-1.165.05-3.434 0-2.27-.009-2.538-.05-3.435-.038-.829-.176-1.279-.293-1.578a2.639 2.639 0 00-.635-.978 2.638 2.638 0 00-.977-.635c-.3-.117-.75-.255-1.58-.293zm-5.107 2.883a4.369 4.369 0 103.343 8.072 4.369 4.369 0 00-3.343-8.072zm7.247-.018a1.033 1.033 0 11-1.892-.833 1.033 1.033 0 011.892.833z'
        fill='#000'
      />
      <path
        d='M13.414 17.12a2.835 2.835 0 102.041-5.29 2.836 2.836 0 00-2.04 5.29z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.996 6.051C11.902 6.009 12.19 6 14.5 6s2.598.01 3.503.051c.906.04 1.524.185 2.065.395.567.214 1.08.549 1.505.981.433.424.767.938.98 1.505.21.54.354 1.159.396 2.063.042.908.051 1.197.051 3.505 0 2.309-.01 2.598-.051 3.504-.041.904-.186 1.523-.396 2.063a4.169 4.169 0 01-.98 1.506 4.167 4.167 0 01-1.505.98c-.54.21-1.159.354-2.063.396-.907.042-1.196.051-3.505.051s-2.598-.01-3.504-.051c-.904-.041-1.523-.186-2.064-.396a4.17 4.17 0 01-1.506-.98 4.166 4.166 0 01-.98-1.505c-.21-.54-.353-1.159-.395-2.063C6.009 17.097 6 16.808 6 14.5c0-2.309.01-2.598.051-3.504.04-.905.185-1.523.395-2.064a4.17 4.17 0 01.981-1.506 4.167 4.167 0 011.505-.98c.54-.21 1.16-.353 2.064-.395zm6.939 1.53c-.897-.041-1.165-.05-3.435-.05s-2.538.01-3.435.05c-.829.038-1.279.176-1.578.293a2.64 2.64 0 00-.978.635c-.282.274-.499.608-.635.978-.117.3-.255.75-.293 1.578-.041.897-.05 1.165-.05 3.435s.01 2.538.05 3.435c.038.829.176 1.279.293 1.579.136.368.353.703.635.977.274.282.609.5.978.635.3.117.75.255 1.578.293.897.041 1.165.05 3.435.05s2.538-.009 3.435-.05c.829-.038 1.279-.176 1.579-.293.396-.154.68-.338.977-.635.282-.274.5-.609.635-.977.117-.3.255-.75.293-1.58.041-.896.05-1.165.05-3.434 0-2.27-.009-2.538-.05-3.435-.038-.829-.176-1.279-.293-1.578a2.639 2.639 0 00-.635-.978 2.638 2.638 0 00-.977-.635c-.3-.117-.75-.255-1.58-.293z'
        fill='#000'
      />
    </svg>
  </a>
)

export const Site = props => (
  <a href='http://sfedu.ru' target='_blank' name='сайт Университета'>
    <svg
      width={29}
      height={29}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx={14.5} cy={14.5} r={14.5} fill='#fff' />
      <path
        d='M18.642 16.4a15.69 15.69 0 00.133-1.9c0-.646-.057-1.273-.133-1.9h3.211a7.83 7.83 0 01.247 1.9 7.83 7.83 0 01-.247 1.9h-3.211zm-1.681 5.282a14.867 14.867 0 001.31-3.382h2.803a7.628 7.628 0 01-4.114 3.382zm-.238-5.282h-4.446a12.605 12.605 0 01-.152-1.9c0-.646.057-1.283.152-1.9h4.446c.085.617.152 1.254.152 1.9 0 .646-.067 1.273-.152 1.9zM14.5 22.062c-.789-1.14-1.425-2.404-1.815-3.762h3.63c-.39 1.358-1.027 2.622-1.815 3.762zM10.7 10.7H7.926a7.527 7.527 0 014.104-3.382c-.57 1.055-.997 2.194-1.33 3.382zm-2.774 7.6H10.7c.333 1.188.76 2.328 1.33 3.382A7.608 7.608 0 017.926 18.3zm-.779-1.9a7.83 7.83 0 01-.247-1.9c0-.655.095-1.292.247-1.9h3.211a15.69 15.69 0 00-.133 1.9c0 .646.057 1.273.133 1.9H7.147zM14.5 6.929c.789 1.14 1.425 2.412 1.814 3.771h-3.629c.39-1.358 1.026-2.631 1.815-3.771zm6.574 3.771h-2.803a14.866 14.866 0 00-1.31-3.382 7.587 7.587 0 014.113 3.382zM14.5 5C9.246 5 5 9.275 5 14.5A9.5 9.5 0 1014.5 5z'
        fill='#000'
      />
    </svg>
  </a>
)

export const Vk = props => (
  <a href='http://vk.com/sfedu_official' target='_blank' name='vk'>
    <svg
      width={29}
      height={29}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx={14.5} cy={14.5} r={14.5} fill='#fff' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.303 10.7c.122-.403 0-.7-.587-.7H19.78c-.493 0-.72.256-.843.539 0 0-.985 2.358-2.38 3.89-.452.444-.657.585-.904.585-.123 0-.308-.141-.308-.545V10.7c0-.483-.136-.699-.546-.699h-3.046c-.308 0-.493.224-.493.438 0 .458.698.564.77 1.854v2.802c0 .615-.113.726-.36.726-.656 0-2.254-2.369-3.202-5.08-.184-.527-.37-.74-.865-.74H5.664c-.553 0-.664.256-.664.539 0 .503.657 3.003 3.058 6.31 1.602 2.257 3.856 3.481 5.91 3.481 1.23 0 1.383-.271 1.383-.74v-1.707c0-.543.116-.652.507-.652.287 0 .78.142 1.93 1.23 1.312 1.29 1.529 1.869 2.268 1.869h1.937c.553 0 .83-.271.671-.809-.175-.534-.803-1.31-1.634-2.23-.452-.523-1.129-1.088-1.335-1.37-.287-.363-.205-.524 0-.847 0 0 2.361-3.265 2.607-4.374z'
        fill='#000'
      />
    </svg>
  </a>
)

export const YouTube = props => (
  <a href='https://www.youtube.com/user/sfeduTV' target='_blank' name='youtube'>
    <svg
      width={29}
      height={29}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx={14.5} cy={14.5} r={14.5} fill='#fff' />
      <path
        d='M23.267 10.94a2.208 2.208 0 00-1.551-1.555C20.336 9.006 14.819 9 14.819 9s-5.516-.006-6.896.356a2.255 2.255 0 00-1.556 1.566C6.004 12.3 6 15.162 6 15.162s-.004 2.874.358 4.239c.202.755.797 1.35 1.552 1.554 1.393.379 6.896.385 6.896.385s5.517.006 6.896-.355a2.214 2.214 0 001.556-1.552c.365-1.379.368-4.238.368-4.238s.017-2.876-.359-4.255zm-10.213 6.871l.005-5.284 4.585 2.647-4.59 2.637z'
        fill='#000'
      />
    </svg>
  </a>
)
